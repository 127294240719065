<template>
  <div>
    <div v-show="totalHits > 0">
      <span class="body-2-code">
        <i18n-t tag="div" class="count" keypath="search.resultsCount" scope="global">
          <template #count>{{ totalHits }}</template>
        </i18n-t>
      </span>
      <div>
        <search-results-item v-for="hit in hits" :key="hit.url" :hit="hit" :query="query" />
      </div>
      <div class="pagination">
        <VPagination
          id="pagination"
          :total="pageCount"
          :selected-index="currentPage"
          @pagination-change="onPaginationChange"
        />
      </div>
    </div>
    <div v-show="totalHits <= 0">
      <i18n-t tag="div" class="count" keypath="search.noResultsForCategory" scope="global">
        <template #query><b v-html="query"></b></template>
      </i18n-t>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, ref, watchEffect } from 'vue';
import { VPagination } from '@vonage/vivid-vue';
import SearchResultsItem from '@/components/search/SearchResultsItem.vue';
import { type SearchResult } from '@/types/search/results';
import { DocumentType, getSearchResultsForQuery } from '@/types/search/search';

const props = defineProps({
  query: { type: String, required: true },
  type: { type: Number as PropType<DocumentType>, required: true },
});

const currentPage = ref(0);
const pageCount = ref(0);
const totalHits = ref(0);
const hits = ref([] as SearchResult[]);

const updateSearchResults = async (type: DocumentType, query: string, page: number) => {
  const results = await getSearchResultsForQuery(type, query, page);
  hits.value = results.hits;
  pageCount.value = results.pages;
  totalHits.value = results.totalHits;
};

watchEffect(() => updateSearchResults(props.type, props.query, currentPage.value));

const onPaginationChange = ({ detail: { selectedIndex } }: { detail: { selectedIndex: number } }) => {
  if (selectedIndex > -1 && currentPage.value !== selectedIndex) {
    currentPage.value = selectedIndex;
  }
};
</script>

<style scoped lang="scss">
@import '../../styles/media';

.count {
  color: var(--vvd-color-neutral-70);
  padding-bottom: 32px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 24px 0;
}

[prev-disabled] .prev,
[next-disabled] .next {
  color: var(--vvd-color-neutral-50);
  pointer-events: none;
}

@media screen and (max-width: $mobile-large) {
  .count {
    padding-bottom: 0;
  }
}

.body-2-code {
  font-size: 14px;
  font-style: normal;
  color: var(--vvd-color-neutral-600);
}
</style>
